import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  withStyles
} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { Decimal } from "decimal.js-light";
import { PAYMENT_MODES, FINANCIAL_METHOD } from "../../../stores/NewEstimateStore";

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  item: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  group: {
    flexDirection: "row"
  }
});

@inject("newEstimateStore")
@observer
class CashForm extends Component {
  componentDidMount() {
    // Used for the ref to work.
    // https://v3-9-0.material-ui.com/demos/text-fields/#components
    // This will probably be better with V4 of MU
    this.forceUpdate();
  }

  /**
   * Compute the data for the TextField.
   */
  getTextFieldValues = amount => ({
    cashPrice: amount,
    deposit: new Decimal(amount)
      .mul(0.3)
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber()
      .toFixed(2),
    balance: new Decimal(amount)
      .mul(0.7)
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber()
      .toFixed(2)
  });

  render() {
    const { classes, newEstimateStore } = this.props;

    return (
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={6} className={classes.item}>
          <FormControl component="fieldset" required>
            <FormLabel component="legend">Moyens de paiement</FormLabel>
            <RadioGroup
              aria-label="paymentMode"
              name="paymentMode"
              className={classes.group}
              value={newEstimateStore.paymentMode}
              onChange={newEstimateStore.handleChange}
            >
              {PAYMENT_MODES.map(method => (
                <FormControlLabel
                  key={method.value}
                  value={method.value}
                  control={<Radio color="primary" />}
                  label={method.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <TextField
            name="cashPrice"
            label="Prix au comptant"
            margin="normal"
            variant="outlined"
            disabled
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>
            }}
            value={newEstimateStore.amount}
          />
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <FormControl fullWidth variant="outlined">
            <InputLabel
              htmlFor="discount"
              ref={ref => {
                // eslint-disable-next-line react/no-find-dom-node
                this.labelDepositRef = ReactDOM.findDOMNode(ref);
              }}
            >
              Acompte à la commande
            </InputLabel>
            <OutlinedInput
              id="deposit"
              name="deposit"
              type="number"
              value={newEstimateStore.cashForm.deposit}
              onChange={newEstimateStore.handleChangeCashForm}
              inputProps={{ min: "0" }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Basculer le mode d'acompte"
                    onClick={newEstimateStore.handleChangeDepositMethod}
                  >
                    {newEstimateStore.cashForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE ? "%" : "€"}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={this.labelDepositRef ? this.labelDepositRef.offsetWidth : 0}
            />
            <FormHelperText id="discount-helper-text">
              soit un acompte de{" "}
              {newEstimateStore.cashForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE
                ? `${newEstimateStore.computedDeposit}€`
                : `${
                    newEstimateStore.cashForm.deposit > 0
                      ? new Decimal((newEstimateStore.cashForm.deposit / newEstimateStore.amount) * 100)
                          .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
                          .toNumber()
                          .toFixed(2)
                      : 0
                  }%`}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <TextField
            name="balance"
            label="Solde à la réception de chantier"
            margin="normal"
            variant="outlined"
            disabled
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>
            }}
            value={newEstimateStore.computedCashFormBalance}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(CashForm);
