import React from "react";
import { Button, Checkbox, FormControlLabel, Grid, Paper, TextField, makeStyles } from "@material-ui/core";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";

import ValidatorService from "../../services/ValidatorService";
import LinkCompanyDialog from "../admin/company/LinkCompanyDialog";
import AdminService from "../../services/AdminService";

const DEFAULT_USER = {
  email: "",
  firstname: "",
  lastname: "",
  phone: "",
  job: "",
  isAdminCompany: false,
  companyId: ""
};

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  },
  center: {
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  formContainer: {
    marginTop: theme.spacing(2)
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  submitBtn: {
    marginLeft: theme.spacing(2)
  }
}));

const UserSchema = yup.object({
  companyId: yup.string().required("Champ requis"),
  email: yup
    .string()
    .email("Email invalide")
    .required("Champ requis"),
  firstname: yup.string().required("Champ requis"),
  lastname: yup.string().required("Champ requis"),
  phone: yup
    .mixed()
    .test("check-phone", "Format invalide", value => (value ? ValidatorService.validatePhone(value) : true)),
  job: yup.string(),
  isAdminCompany: yup.bool()
});

export default function AddUserForm({ user = {}, company = {}, onSuccess = () => {} }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = userToCreate => {
    AdminService.addUser(userToCreate)
      .then(() => {
        enqueueSnackbar("Utilisateur créé", { variant: "success" });
        onSuccess();
      })
      .catch(e => {
        if (e.status && e.status === 409) {
          enqueueSnackbar("Un utilisateur existe déjà avec cette adresse email", { variant: "info" });
        } else {
          enqueueSnackbar("Une erreur est survenue lors de la création de l'utilisateur", { variant: "error" });
        }
      });
  };

  const formik = useFormik({
    initialValues: { ...DEFAULT_USER, ...user, companyId: company ? company.id : user ? user.companyId : "" },
    validationSchema: UserSchema,
    onSubmit: handleSubmit
  });

  const toggleIsAdmin = () => {
    formik.setFieldValue("isAdminCompany", !formik.values.isAdminCompany);
  };

  return (
    <Grid container>
      <Grid item xs={12} className={classes.formContainer}>
        <form>
          <Paper>
            <Grid container className={classes.container} spacing={2}>
              <Grid item xs={12}>
                <LinkCompanyDialog
                  defaultOpen={!company.id}
                  defaultPick={company}
                  onPick={companyId => {
                    formik.setFieldValue("companyId", companyId);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  placeholder="user@mail.com"
                  label="Email"
                  margin="none"
                  variant="outlined"
                  fullWidth
                  required
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  autoComplete="false"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstname"
                  placeholder="John"
                  label="Prénom"
                  margin="none"
                  variant="outlined"
                  fullWidth
                  required
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                  error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                  helperText={formik.touched.firstname && formik.errors.firstname}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="lastname"
                  placeholder="Doe"
                  label="Nom"
                  margin="none"
                  variant="outlined"
                  fullWidth
                  required
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                  error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                  helperText={formik.touched.lastname && formik.errors.lastname}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="phone"
                  placeholder="Numéro de téléphone"
                  label="Téléphone"
                  margin="none"
                  variant="outlined"
                  fullWidth
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="job"
                  placeholder="Utilisateur"
                  label="Job"
                  margin="none"
                  variant="outlined"
                  fullWidth
                  value={formik.values.job}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="sofincoAssociationKey"
                  placeholder="Sofinco - Identifiant"
                  label="PAXXXXXX"
                  margin="none"
                  variant="outlined"
                  fullWidth
                  value={formik.values.sofincoAssociationKey}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isAdminCompany"
                      checked={formik.values.isAdminCompany}
                      onChange={toggleIsAdmin}
                      color="primary"
                    />
                  }
                  label="Admin entreprise"
                />
              </Grid>
              <Grid item xs={12} className={classes.btnContainer}>
                <Button variant="outlined" color="primary">
                  Annuler
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submitBtn}
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  Enregistrer
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </Grid>
    </Grid>
  );
}
