import { Box, Button } from "@material-ui/core";
import React from "react";
import CompaniesSelect from "./CompaniesSelect";

export default function ExportCompanyContent({ onSubmit }) {
  const [companyId, setCompanyId] = React.useState("");

  const handleCompanyChange = _company => {
    setCompanyId(_company.id);
  };

  return (
    <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", gap: "12px" }}>
      <CompaniesSelect onChange={handleCompanyChange} selectedValue={companyId} />
      <Button variant="outlined" color="primary" onClick={() => onSubmit(companyId)}>
        Valider
      </Button>
    </Box>
  );
}
