import { observable, action, computed } from "mobx";
import { createMuiTheme } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import JwtDecode from "jwt-decode";
import moment from "moment";
import CookieService from "../services/CookieService";
import UserService from "../services/UserService";

export const isTokenValid = token => {
  try {
    JwtDecode(token);
    return true;
  } catch (err) {
    return false;
  }
};

class SessionStore {
  @observable
  user = {
    company: {
      color: {}
    }
  };

  @observable
  theme = createMuiTheme({
    palette: {
      primary: { main: "#1a7ec2" },
      secondary: { main: grey[800] }
    },
    typography: {}
  });

  @observable
  siggnedIn = isTokenValid(CookieService.getCookie("token"));

  @observable
  superAdminBreadItems = [{ label: "Accueil BG", path: "/superbg" }];

  @computed
  get estimateLimitDate() {
    if (this.user && this.user.company) {
      return moment().add(this.user.company.estimateLimitDate || 365, "days");
    }
    return moment().add(365, "days");
  }

  @computed
  get orderFormLimitDate() {
    if (this.user && this.user.company) {
      return moment().add(this.user.company.orderFormLimitDate || 365, "days");
    }
    return moment().add(365, "days");
  }

  @action
  signIn = () => {
    this.siggnedIn = true;
  };

  @action
  updateSignIn = () => {
    // this.siggnedIn = isTokenValid(CookieService.getCookie("token"));
    if (isTokenValid(CookieService.getCookie("token"))) {
      this.signIn();
    } else {
      this.signOut();
    }
  };

  @action
  signOut = () => {
    CookieService.deleteCookie("token");
    this.siggnedIn = false;
    this.resetTheme();
    this.user = { company: {} };
  };

  @action
  init = async () => {
    if (this.siggnedIn) {
      await this.processSignIn();
    }
    return Promise.resolve();
  };

  @action
  initLogin = async () => {
    await this.processSignIn();
    this.siggnedIn = true;
    return Promise.resolve();
  };

  @action
  setTheme = ({
    primaryColor = this.theme.palette.primary.main,
    secondaryColor = this.theme.palette.secondary.main
  }) => {
    const shadePrimary = this.theme.palette.augmentColor({ main: primaryColor });
    const shadeSecondary = this.theme.palette.augmentColor({ main: secondaryColor });

    this.theme = {
      ...this.theme,
      palette: {
        ...this.theme.palette,
        primary: shadePrimary,
        secondary: shadeSecondary
      }
    };
  };

  @action
  resetTheme = () => {
    this.theme = createMuiTheme({
      palette: {
        primary: { main: "#1a7ec2" },
        secondary: { main: grey[800] }
      }
    });
  };

  @action
  setSuperAdminBreadItems = items => {
    this.superAdminBreadItems = items;
  };

  @action
  patchSession = sessionData => {
    this.user = sessionData;
    if (sessionData.company && sessionData.company.color) {
      this.setTheme({
        primaryColor: sessionData.company.color.primaryColor,
        secondaryColor: sessionData.company.color.secondaryColor
      });
    }
  };

  @action
  patchSessionCompany = company => {
    this.user.company = company;
  };

  isCompanyAdmin = () => this.user && this.user.isAdminCompany;

  userHasAccess = ({ requiredFeatures, requiredAcls, aclType = "all" }) => {
    const {
      acl = [],
      company: { features },
      profil,
      isAdminCompany
    } = this.user;

    if (features.length === 0) return false;

    let matchFeatures = 0;
    const _features = Array.isArray(requiredFeatures) ? requiredFeatures : [requiredFeatures];
    _features.forEach(i => {
      if (features && features.includes(i)) matchFeatures += 1;
    });

    if ((isAdminCompany || !requiredAcls) && matchFeatures === _features.length) return true;

    let _acl = acl;
    if (profil && profil.acl) {
      _acl = profil.acl;
    }
    let matchAcls = 0;
    const _acls = Array.isArray(requiredAcls) ? requiredAcls : [requiredAcls];
    _acls.forEach(i => {
      if (_acl.includes(i)) matchAcls += 1;
    });

    return (aclType === "oneof" ? matchAcls > 0 : matchAcls === _acls.length) && matchFeatures === _features.length;
  };

  processSignIn = async () => {
    const res = await UserService.me()
      .then(response => response)
      .catch(() => null);
    if (res) {
      this.user = res;
      if (res.company && res.company.color) {
        this.setTheme({
          primaryColor: res.company.color.primaryColor,
          secondaryColor: res.company.color.secondaryColor
        });
      }
    }
    return res;
  };
}

const sessionStore = new SessionStore();

export default sessionStore;
