import { useQuery } from "@tanstack/react-query";
import React from "react";
import { MenuItem, Select } from "@material-ui/core";
import AdminService from "../../services/AdminService";

export default function CompaniesSelect({ selectedValue, onChange }) {
  const { isLoading, data } = useQuery({
    queryKey: ["companies_simplified"],
    queryFn: () => AdminService.getAllCompaniesSimplified()
  });

  const handleChange = e => {
    onChange(data.content.find(c => c.id === e.target.value));
  };

  if (isLoading) return <div>Loading...</div>;

  if (data && data.content && data.content.length > 0) {
    return (
      <Select
        value={selectedValue}
        onChange={handleChange}
        displayEmpty
        variant="outlined"
        fullWidth
        style={{ textAlign: "left" }}
        placeholder=""
      >
        <MenuItem value="" disabled>
          <em> -- Sélectionnez une entreprise --</em>
        </MenuItem>
        {data.content.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    );
  }

  return <div>Aucune entreprise</div>;
}
